.calendar-button {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
    border: 3px solid;
    padding: 0.25em 0.5em;
    box-shadow: 1px 1px 0 0, 2px 2px 0 0, 3px 3px 0 0, 4px 4px 0 0, 5px 5px 0 0;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.calendar-button:active {
    box-shadow: 0 0 0 0;
    top: 5px;
    left: 5px;
}

.calendar-button:hover {
    background: #d2d2d2;
}

@media (min-width: 768px) {
    .calendar-button {
        padding: 0.25em 0.75em;
    }
}
